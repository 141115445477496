import {getKeyTitle} from "@/common/js/tool";

export const satisfactionOptionList = [
    { label: "满意", value: 3, score: 10 },
    { label: "较满意", value: 2, score: 8  },
    { label: "一般", value: 1, score: 6  },
    { label: "不满意", value: 0, score: 4  },
];

export function getSatisfactionOptionScore (val) {
    const item = satisfactionOptionList.find(o => o.value === val);
    return item ? item.score : 0;
}

export function getSatisfactionOptionText (val) {
    return getKeyTitle(satisfactionOptionList, val, "value", "label");
}