<template>
  <div class="detail-list-ctx" :style="{'--label': labelWidth}">
    <ul class="detail-list">
      <li
          class="detail-list-item"
          :class="{'full-row': val.full, vertical: val.vertical}"
          v-for="val in detailList" :key="val.key">
        <div class="detail-list-label">{{val.label}}</div>
        <div class="detail-list-value" v-if="val.imgList">
          <div class="file-list" v-if="val.imgList.length > 0">
            <img class="file-item" :src="f" v-for="(f, i) in val.imgList" :key="i" @click="previewImage(f, val)">
          </div>
          <div v-else>无</div>
        </div>
        <div class="detail-list-value" v-else-if="val.textarea">
          <article v-if="val.value">
            <p class="detail-list-p" v-for="(p, i) in val.value.split(/\r|\n/)" :key="i">{{p}}</p>
          </article>
          <span v-else>无</span>
        </div>
        <div class="detail-list-value" v-else-if="val.rich">
          <div class="rich-preview" v-html="val.value" v-if="val.value"></div>
          <span v-else>无</span>
        </div>
        <div class="detail-list-value" v-else>{{val.value || '无'}}</div>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "DetailList",
  props: {
    list: Array,
    labelWidth: {
        type: String,
        default: "120px"
    }
  },
  computed: {
    detailList() {
      let list = this.list;
      list.forEach(item => {
        if(item.rich || item.textarea || item.imgList) {
          item.full = true;
          // item.vertical = true;
        }
      })
      return list;
    }
  },
  methods: {
    previewImage(current, val) {
      const len = val.imgList.length;
      const list = val.imgList.map((item, i) => {
        return {
          title: `${val.label}${len > 1 ? i + 1 : ""}`,
          url: item
        }
      });
      this.$store.dispatch("showImgList", list, current);
    }
  }
}
</script>

<style scoped lang="less">
.detail-list {
  font-size: 0;
}
.detail-list-item {
  display: inline-flex;
  width: 50%;
  padding: 6px 0;
  line-height: 1.4;
  font-size: 14px;
  &.vertical {
    flex-direction: column;
    .detail-list-value {
      margin: 10px 0 0;
    }
  }
  &.full-row {
    width: 100%;
  }
  .rich-preview {
    width: 484px;
  }
}
.detail-list-label {
  min-width: var(--label);
  color: @text-color-secondary;
}
.detail-list-value {
  margin-left: 20px;
  color: @text-color;
  text-align: justify;
}
</style>
