<template>
  <div class="content md">
    <data-list
      ref="dataList"
      style="height: 100%"
      url="/admin/record-survey"
      entity-name="满意度"
      :add-btn="false"
      hide-edit
      :deal-list="dealList"
      :action="action"
      :columns="columns"
      :request-config="{noTempleFilter: true}"
      :action-width="120"
      @detail="showDetail">
      <template v-slot:query="{form}">
        <a-form-model-item label="">
          <a-input-search v-model="form.name" placeholder="输入设备名称查询"></a-input-search>
        </a-form-model-item>
      </template>
    </data-list>
    <a-modal title="调查详情" :width="640" :footer="null" v-model="visible">
      <detail-list label-width="200px" :list="detailList"></detail-list>
    </a-modal>
  </div>
</template>

<script>

import {getSatisfactionOptionScore, getSatisfactionOptionText} from "@/views/program/data/satisfaction";
import DetailList from "@/layouts/DetailList.vue";

    export default {
        name: "Satisfaction",
      components: {DetailList},
        data() {
          return {
            columns: [
              { title: '填写时间', dataIndex: 'create_time' },
              { title: '个人评分', dataIndex: 'rate' },
            ],
            visible:false,
            detailList: [],
          }
        },
        computed: {
          action() {
            return this.$route.meta.action;
          },
        },
        methods: {
          showDetail({item}) {
            if (!item.detaiList) {
              item.detaiList = [
                { label: "提交时间", key: "create_time", value: item.create_time, full: true },
                { label: "信众名字", key: "name", value: item.name, full: true },
                { label: "地址", key: "location", value: item.location, full: true },
                { label: "联系电话", key: "mobile", value: item.mobile, full: true },
                ...item.scoreList.map((s, index) => {
                  return {
                    label: s.label,
                    key: `rate_${index}`,
                    value: getSatisfactionOptionText(s.value),
                    full: true
                  }
                }),
                { label: "个人评分", key: "rate", value: item.rate, full: true },
              ]
            }
            this.detailList = item.detaiList;
            this.visible = true;
          },
          dealList(list, fn) {
            list.forEach(item => {
              let rate = 0;
              let scoreList = [];
              if (item.score) {
                try {
                  scoreList = JSON.parse(item.score);
                  rate = scoreList.reduce((acc, val) => acc + getSatisfactionOptionScore(val.value), 0);
                  rate = Math.round(rate / 9 * 100) / 100;
                } catch (e) {
                  console.error(e);
                }
              }
              item.scoreList = scoreList;
              item.rate = rate;
            })
            fn();
          }
        }
    }
</script>

<style lang="less">

</style>
